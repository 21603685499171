<template>
	<div>
		<v-layout class="p-4 border-bottom-light-grey min-height-57px">
			<v-flex class="font-level-3-bold my-auto">
				<span class="detail-svg-icon mr-2">
					<!--begin::Svg Icon-->
					<v-icon color="blue darken-4">mdi-receipt</v-icon>
					<!--end::Svg Icon-->
				</span>
				Purchase Order Moment
			</v-flex>
			<v-spacer></v-spacer>
		</v-layout>
		<div class="overflow-y" style="min-height: calc(100vh - 520px)" v-if="!pageLoading">
			<template>
				<table width="100%" class="detail-table table-head-sticky">
					<thead>
						<tr>
							<th width="50" class="p-2 blue lighten-4 text-center">#</th>
							<th class="p-2 blue lighten-4">Purchase Order ID</th>
							<th class="p-2 blue lighten-4">Supplier</th>
							<th class="p-2 blue lighten-4">Old Stock</th>
							<th class="p-2 blue lighten-4">Quantity</th>
							<th class="p-2 blue lighten-4">New Stock</th>
						</tr>
					</thead>
					<tbody v-if="POlisting.length">
						<tr v-for="(row, index) in POlisting" :key="index">
							<td class="p-2 border-top-light-grey text-center">
								{{ index + 1 }}
							</td>
							<td class="p-2 border-top-light-grey text-left">
								<Chip
									v-if="row.purchase_orders_barcode"
									tooltip
									tooltip-text="Purchase Order #"
									small
									outlined
									:label="true"
									color="cyan"
									custom-class="mb-1 mr-1 white--text px-2"
									:text="row.purchase_orders_barcode"
								></Chip>
							</td>
							<td class="p-2 border-top-light-grey">
								<div
									v-if="row.supplier_barcode"
									class="d-flex align-content-start flex-wrap align-start flex-column"
								>
									<div>
										<v-icon small left>mdi-barcode</v-icon>
										<v-chip
											v-if="row.supplier_barcode"
											outlined
											small
											label
											color="cyan white--text text-uppercase"
											class="px-2"
										>
											{{ row.supplier_barcode }}
										</v-chip>
										<em v-else class="text-muted fw-400">no barcode</em>
									</div>
									<div>
										<v-icon small left>mdi-home-city-outline</v-icon>
										<ShowValue
											tooltip
											tooltip-text="company name"
											:object="row"
											object-key="supplier_company_name"
											label="company name"
										></ShowValue>
									</div>
									<div>
										<v-icon small left>mdi-account</v-icon>
										<ShowValue
											tooltip
											tooltip-text="display name"
											:object="row"
											object-key="supplier_name"
											label="display name"
										></ShowValue>
									</div>
									<div>
										<v-icon small left>mdi-email</v-icon>
										<ShowValue
											tooltip
											click-to-copy
											tooltip-text="Email"
											:object="row"
											object-key="supplier_email"
											label="email"
										></ShowValue>
									</div>
									<div>
										<v-icon small left>mdi-phone</v-icon>
										<ShowValue
											tooltip
											click-to-copy
											tooltip-text="phone number"
											:object="row"
											object-key="supplier_phone"
											label="phone number"
										>
										</ShowValue>
									</div>
								</div>
							</td>
							<td class="p-2 border-top-light-grey">
								<ShowValue :object="row" object-key="old_stock" label="Old stock"></ShowValue>
							</td>
							<td class="p-2 border-top-light-grey">
								<ShowValue :object="row" object-key="stock_quantity" label="quantity"></ShowValue>
							</td>
							<td class="p-2 border-top-light-grey">
								<ShowValue :object="row" object-key="new_stock" label="New stock"></ShowValue>
							</td>
						</tr>
					</tbody>

					<tfoot v-else>
						<tr>
							<td colspan="11">
								<p class="m-0 row-not-found text-center py-3">
									<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
									Uhh... There are no purchase order at the moment.
								</p>
							</td>
						</tr>
					</tfoot>
				</table>
			</template>
		</div>
		<div v-else class="mx-20 my-40 text-center">
			<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
		</div>
		<v-row v-if="totalPage" class="my-2">
			<v-col md="6" class="my-auto">
				<p class="m-0">{{ showingString }}</p>
			</v-col>
			<v-col md="6" class="text-right">
				<v-pagination
					color="blue darken-4"
					v-model="page"
					:length="totalPage"
					total-visible="9"
					v-on:input="getPoMomentHistory($event)"
				></v-pagination>
			</v-col>
		</v-row>
	</div>
</template>
<script>
import ShowValue from "@/view/components/ShowValue";
// import { cloneDeep } from "lodash";
// import moment from "moment";
import Chip from "@/view/components/Chip";
import { GetPOMomentHistory } from "@/core/lib/inventory.lib.js";

import { SET_ERROR } from "@/core/services/store/common.module";
// import ImageTemplate from "@/view/components/Image";
export default {
	name: "ProductPriceListing",
	props: {
		quotationListing: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			pageLoading: false,
			POlisting: [],
			page: 1,
			showingString: null,
			totalPage: 0,
		};
	},
	methods: {
		async getPoMomentHistory(page = 1) {
			try {
				const { tbody, current_page, total_page, showing_string } = await GetPOMomentHistory(
					this.$route.params.uuid,
					page
				);
				this.current_page = current_page;
				this.totalPage = total_page;
				this.showingString = showing_string;
				this.POlisting = tbody;
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			}
		},
	},

	components: {
		Chip,
		ShowValue,
	},
	async mounted() {
		await this.getPoMomentHistory();
	},
};
</script>
